<template>
  <section class="culture">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-row :gutter="30">
        <el-col :span="24" :offset="0"><div class="title"><h1>{{ options.title }}</h1><p><strong>{{ options.tit }}</strong></p></div></el-col>
      </el-row>
      <el-row :gutter="30">
          <ArticleContent :data="options.content" />
      </el-row>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/关于我们.png'),
        slogan: require('../../assets/images/2023/aboutus/slogan.png'),
      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      let option
      if ( id ) {
        switch ( id ) {
          case '8':
            option = this.$store.getters.getCulture && JSON.parse(JSON.stringify(this.$store.getters.getCulture))
            this.setOptions(option)
            break
          default:
            option = this.$store.state.getCulture
            this.setOptions(option)
        }
      }
    },
    setOptions (obj) {
      this.$set(this.options, 'title', obj.title)
      this.$set(this.options, 'content', obj.content)
      if (obj.tit) this.$set(this.options, 'tit', obj.tit)
      if (obj.url) this.$set(this.options, 'url', obj.url)
    },
  }
}
</script>

<style scoped>
  .culture {background-color: #ecf0f5; padding-bottom: 1rem;}
  
  .culture .title {font-weight: 600; border-bottom: 1px solid #666; padding: 0 0 1rem;}
  .culture .title h1 {font-size: 3rem; color: rgba(82, 0, 0, .9);line-height:1.6; font-weight: 600;}
  .culture .container .ArticleContent /deep/ img.logo {width: 20%; margin: 1rem auto; position: relative; left: 50%; transform: translate(-50%, 0)}
</style>